import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  GroupType,
  ITableOptions, ManagerCoursesSelectGetParams, ManagerCoursesSelectGetRequest,
  ManagerCoursesTreeSelectGetRequest,
  ManagerSalesGetRequest,
  ManagerSalesPostRequest, ManagerSalesSaleIdActivatePatchRequest,
  ManagerSalesSaleIdDeleteRequest,
  ManagerSalesSaleIdGetRequest,
  ManagerSalesSaleIdPatchRequest,
  ManagerSalesUnitsPostRequest,
  NameValueChildrenResource,
  NameValueResource,
  SaleCourseUnitResource, SalePackageUnitResource,
  SaleResource,
  SaleResourcePaginator,
  SaleStoreData,
  UnitsRequest,
} from '@/store/types'

/**
 * Работа со скидками для прдеметов в курсе
 * - получение списка предметов и скидок
 * - создание/редактирование скидки по предмету
 * - удаление скидки по предмету
 */

@Module({
  dynamic: true,
  name: 'ManagerDiscount',
  namespaced: true,
  store,
})
class ManagerDiscount extends VuexModule {
  // ---------------------------- Discounts ---------------------------- >>
  discounts: SaleResourcePaginator = GET_DEFAULT_PAGINATOR()
  discountsFilter: any = {
    ...GET_DEFAULT_TABLE_FILTER(),
    perPage: 10,
  }

  @Mutation
  setDiscounts (payload: SaleResourcePaginator) {
    this.discounts = { ...payload }
  }

  @Mutation
  setDiscountsFilter(payload: any) {
    this.discountsFilter = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchDiscounts (filter: ITableOptions): Promise<SaleResourcePaginator> {
    const { data } = await ManagerSalesGetRequest(filter)
    return data
  }

  @Action({ rawError: true })
  async fetchDiscount (saleId: number) {
    const { data } = await ManagerSalesSaleIdGetRequest(saleId)
    this.setDiscount(data)
    return data
  }

  @Action({ rawError: true })
  async deleteDiscount (saleId: number) {
    const { data } = await ManagerSalesSaleIdDeleteRequest(saleId)
    this.setDiscount(null)
    return data
  }

  discount: SaleResource | null = null

  @Mutation
  setDiscount (payload: SaleResource | null) {
    this.discount = payload
  }

  @Action({ rawError: true })
  async saveDiscount (payload: { saleId?: number, data: SaleStoreData }) {
    if (payload.saleId) {
      const { data } = await ManagerSalesSaleIdPatchRequest(payload.saleId, payload.data)
      this.setDiscount(data)
      return data
    } else {
      const { data } = await ManagerSalesPostRequest(payload.data)
      this.setDiscount(data)
      return data
    }
  }

  // @Action({ rawError: true })
  // async fetchSalesUnits(payload: SaleUnitType) {
  //   const { data } = await ManagerSalesUnitsPostRequest({ type: payload })
  //   return data
  // }

  discountCoursesFilter: NameValueChildrenResource[] = []

  @Mutation
  setDiscountCoursesFilter(payload: NameValueChildrenResource[]) {
    this.discountCoursesFilter = payload
  }

  @Action({ rawError: true })
  async fetchDiscountCoursesFilter(filter: { isPublished?: boolean, subjectId?: number, groupType?: GroupType }) {
    const { data } = await ManagerCoursesTreeSelectGetRequest(filter)
    this.setDiscountCoursesFilter(data)
    return data
  }

  discountPositionsSources: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource> = []
  discountPositionsTargets: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource> = []

  @Mutation
  setDiscountPositionsSources(payload: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource>) {
    this.discountPositionsSources = payload
  }

  @Mutation
  setDiscountPositionsTargets(payload: Array<NameValueResource|SaleCourseUnitResource|SalePackageUnitResource>) {
    this.discountPositionsTargets = payload
  }

  @Action({ rawError: true })
  async fetchDiscountPositionsSources(payload: UnitsRequest) {
    const { data } = await ManagerSalesUnitsPostRequest(payload)
    this.setDiscountPositionsSources(data.units)
    return data
  }

  @Action({ rawError: true })
  async fetchDiscountPositionsTargets(payload: UnitsRequest) {
    const { data } = await ManagerSalesUnitsPostRequest(payload)
    this.setDiscountPositionsTargets(data.units)
    return data
  }

  @Action({ rawError: true })
  async activatedDiscount(saleID: number) {
    const { data } = await ManagerSalesSaleIdActivatePatchRequest(saleID)
    this.setDiscount(data)
    return data
  }

  @Action({ rawError: true })
  async fetchCoursesSelect(payload?: ManagerCoursesSelectGetParams) {
    const { data } = await ManagerCoursesSelectGetRequest(payload)
    return data
  }
}

const ManagerDiscountModule = getModule(ManagerDiscount)

export default ManagerDiscountModule
